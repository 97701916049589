<template>
   <div class="row">
      <div class="col-12">
         <div class="card p-sm-3">
            <div class="card-body p-sm-4">
               <div class="row">
                  <div class="wpx-250 mx-auto mx-md-0 mb-3 mb-md-0">
                     <img width="100%" class="rounded" :src="classroom.nomeArquivo == null ? '' : classroom.nomeArquivo" alt="banner" @error="imageError">
                  </div>
                  <div class="col-md">
                     <div class="row">
                        <div class="col">
                           <h4 class="limitador">{{ classroom.nome }}</h4>
                        </div>
                        <div class="w-max-content">
                           <i class="far fa-undo-alt font-20 cursor-pointer float-end" @click="fecharModulo"></i>
                        </div>
                     </div>
                     <p class="limitador-3"><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ classroom.descricao != null ? classroom.descricao : 'Ver mais' }}</p>
                     <span class="font-16"><i class="far font-14 me-1" :class="totalAssistidas == totalAulas && totalAulas != 0 ? 'text-success fa-check' : 'fa-eye color-theme'"></i>{{totalAssistidas == totalAulas && totalAulas != 0 ? 'Concluido!' : totalAssistidas + '/' + totalAulas}}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-for="(bloco, index) in listaBlocos" :key="index">
         <div class="card">
            <div class="card-body">
               <h4 class="limitador font-22 mb-2">{{bloco.titulo}}</h4>
               <p class="text-secondary font-17 mb-2"><i class="far fa-books font-14 me-1"></i>Aulas</p>

               <div class="card cursor-pointer mb-1" :class="index % 2 == 0 ? 'invert' : ''" v-for="(aula, index) in bloco.aulas" :key="index" @click="abrirAula(bloco, aula)">
                  <div class="card-body p-12">
                     <div class="row align-items-center">
                        <div class="w-max-content">
                           <i class="far fa-play color-theme font-16"></i>
                        </div>
                        <div class="col-md">
                           <h6 class="font-16 mb-1">{{ aula.tituloAula }}</h6>
                           <p class="font-14 limitador text-secondary mb-0">{{ aula.descricao }}</p>
                        </div>
                        <div class="w-max-content">
                           <i class="fas fa-eye font-16 text-success" v-if="aula.visualizado"></i>
                           <i class="fas fa-eye font-16" v-else></i>
                        </div>
                     </div>
                  </div>                           
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Classroom',
	data: function() {
		return {
         classroom: {},
         listaBlocos: [],
         bloco: {'idModulo': null, 'id': null, 'titulo': null},
         aula: {'id': null, 'titulo': null, 'descricao': null, 'link': null, 'data': null, 'bloco': null},
         moduloAtual: null,
         totalAulas: 0,
         totalAssistidas: 0,
		}
	},
   created() {
      this.moduloAtual = this.$route.params.idModulo;
      this.searchAllFromModulo(this.moduloAtual);
   },
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
	methods: {
      searchAllFromModulo : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.classroom = {};
         this.listaBlocos = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicosAdmin/searchAllFromModulo',
            params: {
               id: this.moduloAtual,
               idUsuario: this.dadosUsuario.id
            }
         }).then(response => {
				ref.classroom = response.data;
            ref.listaBlocos = response.data.blocos;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirAula: function(bloco, aula) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admAula/' + this.moduloAtual + '/' + bloco.id + '/' + aula.idAula);
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      fecharModulo : function() {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.$router.push('/admMeusCursos');
         this.$store.dispatch('alternarTelaCarregamento', false)
      },
      calcularAulas : function() {
         let ref = this
         for (let i = 0; i < ref.listaBlocos.length; i++) {
            for (let h = 0; h < ref.listaBlocos[i].aulas.length; h++) {
               ref.totalAulas = ref.totalAulas + 1
               if (ref.listaBlocos[i].aulas[h].visualizado == true) {
                  ref.totalAssistidas = ref.totalAssistidas + 1
               }
            }
         }
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
	},
   mounted() {
      setTimeout(() => {
         this.calcularAulas()
      }, 100);
   }
}

</script>

<style scoped>
img {
	aspect-ratio: 3/4;
	object-fit: cover;
}
</style>